.MyPageHead {
  width: 100%;
  background-color: #161A24;
  height: 100%;
  
  .content {
    width: 1200px;
    margin: 0 auto;

    .pageInfoLayer {
      width: 100%;
      height: 15px;
      margin-top: 22px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #999999;
  
      .nextIcon {
        margin-left: 5px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }

    .buttonLayer {
      justify-content: space-between;
      margin-top: 23px;
      flex-direction: row;
      display: flex;
      .title {
        width: fit-content;
        display: flex;
        font-size: 30px;
        height: 40px;
        align-items: center;
        font-family: Noto Sans KR;
      }
      .buttons {
        width: fit-content;
        display: flex;
        justify-content: flex-end;
        .emailButton {
          width: fit-content;
          min-width: 174px;
          height: 40px;
          background-color: #232732;
          color: #ccc;
          border-radius: 6px;
          font-family: Noto Sans KR;
          text-transform: none;
          font-size: 14px;
          line-height: 16px;
          padding: 20px;
          .text {
            font-weight: 100;
          }
        }

        .pointButton {
          height: 40px;
//          background-color: #232732;
          border-radius: 6px;
          align-items: center;
          display: flex;
          justify-content: flex-start;
          font-family: Noto Sans KR;
          margin-left: 10px;
          
          
          .svg {
            display: flex;
            align-items: center;
            >object {
              width: 16px !important;
              height: 16px !important;
            }
          }
          .text {
            font-size: 14px;
            line-height: 17px;
            color: #999999;
            margin-left: 5px;
            margin-top: 1px;
            font-family: Noto Sans KR;
            font-weight: 100;
          }
          .point {
            text-align: right;
           // margin-right: 15px;
           // margin-left: 20px;
            font-size: 18px;
            line-height: 21px;
            color: #CCCCCC;
            margin-left: 7px;
            font-family: Roboto;
            font-weight: 400;
          }
        }

        .switchTabs {
          display: flex;
          background-color: #383B45;
          height: 36px;
          border-radius: 6px;
          padding: 2px;
          align-items: center;
          text-align: center;
          margin-left: 10px;
          

          .switchButton {
            font-size: 12px;
            line-height: 128.7%;
            height: 36px;
            background-color: #383B45;
            color: #CCCCCC;
            padding: 0px;
            font-family: Noto Sans KR;

            &:hover {
              background-color: #60626a;
              color: #eee;
            }

            &.enable {
              background-color: #EEEEEE;
              color: #232732;
            }
          }
        }

      }
    }
    >.servicePlan {
      color: #999;
      .service-plan-title {
        color: #EEEEEE;
        font-size: 16px;
        height: 50px;
        box-sizing: border-box;
        padding: 1px 0;
      }
      .no-plan {
        .link {
          color: #48DDF2;
          margin-left: 5px;
          cursor: pointer;
        }
      }
      
      display: flex;

      .divider {
        border: 1px solid #333333;
        margin: 20px;
        height: 99px;
      }
      article:first-child {
        width: 300px;
        .key-value {
          margin-top: 8px;
          width: 300px;
          height: 40px;
        }
    
      }
      article {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .key-value {
          width: 330px;
          height: 33px;
         align-items: center;
          display: flex;
          justify-content: space-between;

          .icon {
            width: 25px !important;
            height: 25px !important;
            cursor: pointer;
            display: flex;
//            align-items: center;
            justify-content: center;
            >.help-icon {
              width:  14px;
              color: #999;
            }
            >.info-icon {
              width: 14px;          
            }
            
          }
          >.key {
            font-size: 14px;
            display: inline-flex;
            align-items: center; 
            line-height: 33px;
            

            
          }
          >.value {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #EEEEEE;

          }

        }
      }
      .PLATINUM-wrapper {
        width: 149px;
        height: 40px;
        border-radius: 6px;
        border: 1px solid #48DDF2;
        font-weight: 700;
        line-height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        letter-spacing: -0.05em;
      //  justify-content: space-around;
        box-sizing: border-box;
        .PLATINUM {
          background: linear-gradient(95.5deg, #48DDF2 16.29%, #95E4B3 84.25%);
          background-clip: text;
          color: transparent;
          font-size: 18px;
        }
        .PLATINUM-using {
          margin-left: 3px;
          color: #48DDF2;
          font-size: 14px;

        }
      }

    }
  }
}
