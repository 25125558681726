.dropFileBox {
  position: absolute;
  font-family: Noto Sans KR;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #232b33;
  z-index: 1;
  display: flex;
  flex-direction: column;
  text-align: center;

  .content {
    position: relative;
    margin: auto;
    .button {
      width: 360px;
      height: 40px;
      margin-top: 20px;
      background: linear-gradient(to right, #66dfd9, #95e4b3);
      color: #232732;
      font-weight: bold;
      font-size: 18px;
    }

    .topText {
      font-size: 24px;
      width: 280px;
      margin: auto;
      color: #cccccc;
    }

    .bottomText {
      font-size: 18px;
      margin-top: 20px;
      color: #cccccc;
    }
    &.noEvent {
      pointer-events: none;
    }

    &.topMargin40 {
      margin-top: 40px;
    }
  }

  > .file-drop {
    position: absolute;
    width: 100%;
    height: 100%;
    .file-drop-target {
      height: 100%;
    }
    .cover {
      width: 100%;
      height: 100%;
      display: none;
    }
  }
  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    .cover.hidden {
      display: block;
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      background: #c4c4c4;
      pointer-events: none;
      opacity: 0.3;
    }
  }

  &.hidden {
    display: none;
  }

  .sampleExample {
    position: relative;
    width: 700px;
    height: 218px;
    background: #111a22;
    display: flex;
    margin: auto auto 0 auto;
    
    &.hidden {
      display: none;
    }
  
    .sampleImg {
      margin: auto;
    }
    .sampleText {
      margin: auto;
      a{
        text-decoration: none;
      }

      .title {
        color: #eee;
        font-size: 26px;
        margin-bottom: 12px;
        width: fit-content;
      }

      .content {
        width: fit-content;
        margin-left: 0px;
        color: #ccc;
      }

      .downloadButton {
        width: 260px;
        height: 40px;
        margin-top: 13px;
        display: flex;
        background: #383b45;
        border-radius: 6px;
        color: #eee;
        cursor: pointer;
        > span {
          margin: 10px;
        }
        .donwloadIcon {
          margin: 10px;
          margin-left: auto;
        }
      }
    }
  }
}
