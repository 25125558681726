.Switch {
  display: inline-flex;
  align-items: center;
  flex-flow: row;

  >.rc-switch {
    background-color: #555;
    border-color: #555;
    width: 36px;
    height: 20px;
    &.rc-switch-checked {
      &.AI {
        border: 1px solid #01BAEF;
        background-color: #01BAEF;
      }
         
      &.DESIGNER{
        border: 1px solid #B71DFF;
        background-color: #B71DFF;
      }
      &:after {
        width: 16px;
        height: 16px;
        left: 18px;
      }
    }
    &:after {
      width: 16px;
      height: 16px;
    }
    &:hover {
      &:after {
        transform: none;
        -webkit-transform: none;
        animation-name: none;
        -webkit-animation-name: none;

      }
    }
  }
  >.name {
    margin-left: 5px;
    color: #eee;
    font-size: 12px;
    line-height: 12px;
  }
}